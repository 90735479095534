import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    transform(text: string, maxLength = 30): string {
        return !!text ? (text.length > maxLength ? text.substring(0, maxLength) + '...' : text) : null;
    }
}
