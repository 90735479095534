<mat-card class="mb-2">
    <mat-card-header>
        <mat-card-title>Custom Data Types</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list>
            <mat-list-item
                *ngFor="let customDataType of customDataTypes"
                class="mdc-list-item--with-trailing-icon viso-mat-list"
                [lines]="2"
            >
                <span matListItemIcon>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="toggleDataTypeStatus(customDataType)">
                            <mat-icon>{{ customDataType.enabled ? 'toggle_on' : 'toggle_off' }}</mat-icon>
                            <span>{{ customDataType.enabled ? 'Disable' : 'Enable' }}</span>
                        </button>
                        <button
                            mat-menu-item
                            (click)="openManageCustomDataTypeModal(manageCustomConfigModal, customDataType)"
                        >
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                    </mat-menu>
                </span>
                <span matListItemAvatar>
                    <app-chip [type]="customDataType.enabled ? 'basic-outline' : 'danger-outline'" [condensed]="true">
                        {{ customDataType.enabled ? 'Active' : 'Inactive' }}
                    </app-chip></span
                >
                <span matListItemTitle class="title">{{ customDataType.dataType.category }}</span>
                <span matListItemLine class="name">{{ customDataType.dataType.name }}</span>
                <span matListItemLine class="description">{{ customDataType.dataType.description }}</span>
                <span matListItemMeta
                    ><app-sensitivity-meter
                        [sensitivity]="getSensitivityValueForBar(customDataType.dataType.sensitivity)"
                        [sensitivityLevel]="customDataType.dataType.sensitivityLevel"
                    ></app-sensitivity-meter
                ></span>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button visoButton="primary" (click)="openManageCustomDataTypeModal(manageCustomConfigModal)">
            Custom Data Type
        </button>
    </mat-card-actions>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title>Default Data Types</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list>
            <mat-list-item
                *ngFor="let defaultDataType of systemDataTypes"
                class="mdc-list-item--with-trailing-icon viso-mat-list"
                [lines]="2"
            >
                <span matListItemIcon>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="toggleDataTypeStatus(defaultDataType)">
                            <mat-icon>{{ defaultDataType.enabled ? 'toggle_on' : 'toggle_off' }}</mat-icon>
                            <span>{{ defaultDataType.enabled ? 'Disable' : 'Enable' }}</span>
                        </button>
                        <button
                            mat-menu-item
                            (click)="openManageCustomDataTypeModal(manageCustomConfigModal, defaultDataType)"
                        >
                            <mat-icon>content_copy</mat-icon>
                            <span>Duplicate</span>
                        </button>
                    </mat-menu>
                </span>
                <span matListItemAvatar>
                    <app-chip [type]="defaultDataType.enabled ? 'basic-outline' : 'danger-outline'" [condensed]="true">
                        {{ defaultDataType.enabled ? 'Active' : 'Inactive' }}
                    </app-chip></span
                >
                <span matListItemTitle class="title">{{ defaultDataType.dataType.category }}</span>
                <span matListItemLine class="name">{{ defaultDataType.dataType.name }}</span>
                <span matListItemLine class="description">{{ defaultDataType.dataType.description }}</span>
                <span matListItemMeta
                    ><app-sensitivity-meter
                        [sensitivity]="getSensitivityValueForBar(defaultDataType.dataType.sensitivity)"
                        [sensitivityLevel]="defaultDataType.dataType.sensitivityLevel"
                    ></app-sensitivity-meter
                ></span>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>

<ng-template #manageCustomConfigModal let-modal>
    <form [formGroup]="customDataTypesForm" name="customDataTypesForm" role="form" (ngSubmit)="submitDataType()">
        <div class="modal-header">
            <div class="modal-title title-text">
                {{ currentDataTypeOrg ? 'Edit Custom Data Type' : 'New Custom Data Type' }}
            </div>
        </div>
        <div class="modal-body modal-text">
            <div class="form-group">
                <label for="name"><strong>Name</strong></label>
                <input id="name" type="text" class="form-control" formControlName="name" [disabled]="savingDataType" />
                <span class="text-danger" *ngIf="showNameRequiredError">This name is required</span>
            </div>
            <div class="form-group">
                <label for="description"><strong>Description</strong></label>
                <textarea
                    id="description"
                    rows="5"
                    cols="50"
                    class="form-control"
                    formControlName="description"
                    [disabled]="savingDataType"
                ></textarea>
                <span class="text-danger" *ngIf="showDescriptionRequiredError">This description is required</span>
            </div>
            <div class="form-group">
                <label for="exampleText"><strong>Example Text</strong></label>
                <textarea
                    id="exampleText"
                    rows="5"
                    cols="50"
                    class="form-control"
                    formControlName="exampleText"
                    [disabled]="savingDataType"
                ></textarea>
            </div>
            <div class="form-group">
                <span for="category"><strong>Category</strong></span>
                <select class="form-control" id="category" formControlName="category">
                    <option [ngValue]="null">Select a category</option>
                    <option [ngValue]="dataTypeCategory" *ngFor="let dataTypeCategory of dataTypeCategories">
                        {{ dataTypeCategory }}
                    </option>
                </select>
                <span class="text-danger" *ngIf="showCategoryRequiredError">Category is required</span>
            </div>
            <div class="form-group">
                <label for="sensitivity"><strong>Sensitivity</strong></label>
                <input
                    id="sensitivity"
                    (wheel)="$event.preventDefault()"
                    type="number"
                    min="0"
                    max="1"
                    class="form-control"
                    formControlName="sensitivity"
                    [disabled]="savingDataType"
                />
                <span class="text-info"
                    ><strong
                        >Data Sensitivity Level:
                        {{ getSensitivityLevelFromValue(customDataTypesForm.get('sensitivity').value) }}</strong
                    ></span
                >
                <br />
                <span class="text-danger" *ngIf="showSensitivityRequiredError">This sensitivity is required</span>
                <span class="text-danger" *ngIf="showSensitivityValueLimitError"
                    >The sensitivity value must be between 0 and 1</span
                >
            </div>
            <label for="relevantControlDomainIds" class="filter-label"><strong>Control Domains:</strong></label>
            <ngx-select
                name="Control Domains"
                id="relevantControlDomainIds"
                class="filter-control"
                [items]="orgProfileControls$ | async"
                [multiple]="true"
                optionTextField="name"
                optionValueField="id"
                formControlName="relevantControlDomainIds"
                placeholder="Map Control Domains"
                [autoClearSearch]="true"
            >
            </ngx-select>
        </div>
        <div class="modal-footer d-flex justify-content-between">
            <button
                type="button"
                mat-button
                visoButton="warning"
                [disabled]="savingDataType"
                (click)="closeManageCustomDataTypeModal()"
            >
                Cancel
            </button>
            <div>
                <button mat-button visoButton="primary" type="submit" [disabled]="savingDataType">
                    {{ savingDataType ? 'Saving...' : 'Submit' }}
                </button>
            </div>
        </div>
    </form>
</ng-template>
