<header class="content-heading">
    <section>
        Feature Flags
        <small>Manage new feature releases</small>
    </section>
</header>

<main>
    <section>
        <ngx-datatable
            #featuresFlagTable
            class="bootstrap"
            [rows]="featureFlags"
            [loadingIndicator]="loadingFeatureFlags"
            [columnMode]="'force'"
            [rowHeight]="35"
            [headerHeight]="30"
            [count]="featureFlags?.length"
        >
            <ngx-datatable-column name="Flag" prop="flag"></ngx-datatable-column>
            <ngx-datatable-column [width]="100" [resizable]="false" [cellClass]="'p-0'">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="actions d-flex justify-content-between">
                        <button
                            mat-button
                            visoButton="link"
                            class="ngx-dt-action persistent"
                            (click)="editFeatureFlag(manageFeatureFlagModal, row.flag)"
                        >
                            EDIT
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </section>
</main>

<ng-template #manageFeatureFlagModal let-modal>
    <div class="modal-header">
        <div class="modal-title title-text">Edit Feature Flag: {{ selectedFeatureFlag.featureFlag }}</div>
    </div>
    <div class="modal-body modal-text">
        <form [formGroup]="featureFlagForm">
            <div class="form-group">
                <mat-slide-toggle
                    color="primary"
                    labelPosition="before"
                    [disableRipple]="true"
                    formControlName="enabled"
                    [disabled]="updatingFeatureFlags || loadingFeatureFlag"
                >
                    Enabled
                </mat-slide-toggle>
            </div>
            <div class="form-group">
                <label for="field_riskLevel">Orgs</label> <br />
                <ngx-select
                    name="Active Orgs"
                    id="activeOrgs"
                    [items]="orgs$ | async"
                    [multiple]="true"
                    optionTextField="name"
                    optionValueField="id"
                    formControlName="orgs"
                    placeholder="Select Orgs"
                    [autoClearSearch]="true"
                    [disabled]="updatingFeatureFlags || loadingFeatureFlag"
                >
                </ngx-select>
            </div>
        </form>
    </div>
    <div class="modal-footer d-flex">
        <button
            type="button"
            mat-button
            visoButton="primary"
            cdkFocusInitial
            (click)="modal.dismiss()"
            [disabled]="updatingFeatureFlags || loadingFeatureFlag"
        >
            Close
        </button>
    </div>
</ng-template>
