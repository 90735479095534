<div ng2FileDrop class="dropzone" [uploader]="uploader" [ngClass]="{ 'on-drag': (onDrag$ | async) }">
    <div class="on-drag-area border border-primary text-primary rounded justify-content-center align-items-center">
        <p>Drop files here.</p>
    </div>
    <div mat-dialog-title>
        {{ titleLabel }}
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p class="full-flex-row" [ngClass]="!!trustPortalUrl ? 'mb-3' : 'mb-0'">
            Select how you would like us to collect information for this assessment.<br />
            Every assessment includes collection and analysis of publicly available information.
        </p>
        <app-disclaimer type="info" *ngIf="!!trustPortalUrl">
            {{ thirdPartyOrgName }} has a trust portal, which may contain private artifacts for business partners.
            Access the artifacts at
            <a
                class="mat-text-secondary text-decoration-underline"
                [externalLink]="trustPortalUrl"
                [matTooltip]="trustCenterLinkTooltip"
            >
                {{ trustPortalUrl | truncate: MAX_TRUST_CENTER_LENGTH }}
            </a>
            @if (trustCenterIsFromSafeBase) {
                <div class="safebase-disclaimer d-flex align-items-center pt-2">
                    <span>In partnership with <strong>SafeBase</strong></span>
                    <div class="ps-2 d-flex align-items-center">
                        <mat-icon svgIcon="safebase" [inline]="true"></mat-icon>
                    </div>
                </div>
            }
        </app-disclaimer>
        <ng-container *ngIf="currentUserAvailableAssessments$ | async as availableAssessments">
            <p *ngIf="doesCurrentUserHaveTrialLicense$ | async" class="full-flex-row trial-info-container p-3">
                You have
                <strong
                    >{{
                        availableAssessments === 1
                            ? availableAssessments + ' assessment'
                            : availableAssessments + ' assessments'
                    }}
                    left</strong
                >
                as part of your trial account. Please contact sales&#64;visotrust.com for more information.
            </p>
        </ng-container>

        <form [formGroup]="startAssessmentFormGroup" class="mt-3">
            <mat-radio-group class="text-bold" formControlName="assessmentInfoTypeSelection" color="primary">
                <mat-radio-button
                    [value]="AssessmentInfoTypeValue.THIRD_PARTY_INFO"
                    data-pw="thirdPartyAssessmentRadio"
                >
                    Designate a third party contact to reach out to
                </mat-radio-button>
                <ng-container *ngIf="assessmentInfoTypeValue === AssessmentInfoTypeValue.THIRD_PARTY_INFO">
                    <div class="selected-option-content">
                        <mat-radio-group
                            class="contact-radio-group"
                            color="primary"
                            [formControl]="useThirdPartyOrOtherRecipientFormControl"
                        >
                            <ng-container *ngIf="this.hasPrimaryVendorContact">
                                <mat-radio-button
                                    id="primaryContactRadio"
                                    [value]="ThirdPartyOptions.PrimaryVendorContact"
                                    >Primary third party contact
                                </mat-radio-button>
                            </ng-container>
                            <ng-container
                                *ngIf="useThirdPartyOrOtherRecipient === ThirdPartyOptions.PrimaryVendorContact"
                            >
                                <div class="bg-light py-2 ps-4 me-2 pe-2 pe-4 ms-5 rounded">
                                    <div>
                                        <p class="m-0">{{ primaryContactName }}</p>
                                        <strong>{{ primaryContactEmail }}</strong>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="suggestedContacts.length">
                                <mat-radio-button
                                    id="vendorDirectoryRadio"
                                    data-pw="vendorDirectoryRadio"
                                    [value]="ThirdPartyOptions.VendorDirectoryContact"
                                    >Directory profile contact
                                </mat-radio-button>
                                <ng-container
                                    *ngIf="useThirdPartyOrOtherRecipient === ThirdPartyOptions.VendorDirectoryContact"
                                >
                                    <ngx-select
                                        required
                                        class="ps-4 pe-4 ms-md-4"
                                        [formControl]="suggestedContactFormControl"
                                        [items]="suggestedContacts"
                                        [isFocused]="false"
                                        data-pw="suggestedContactsSelect"
                                        placeholder="Select an existing contact"
                                    />
                                </ng-container>
                            </ng-container>

                            <mat-radio-button
                                id="otherRecipientRadio"
                                *ngIf="this.hasPrimaryVendorContact || suggestedContacts.length"
                                [value]="ThirdPartyOptions.OtherRecipient"
                                >Another recipient
                            </mat-radio-button>
                            <ng-container *ngIf="useThirdPartyOrOtherRecipient === ThirdPartyOptions.OtherRecipient">
                                <form class="mt-2 pt-0 ps-1 me-4" formGroupName="otherRecipient">
                                    <div class="form-group required">
                                        <input
                                            id="firstName"
                                            type="text"
                                            class="form-control"
                                            formControlName="firstName"
                                            required
                                            minlength="2"
                                            placeholder="First Name"
                                            data-pw="firstNameInput"
                                        />
                                        <span class="text-danger" *ngIf="showRecipientFirstNameRequiredError"
                                            >This field is required</span
                                        >
                                        <span class="text-danger" *ngIf="showRecipientFirstNameMinLengthError"
                                            >Given name must be at least 2 characters long</span
                                        >
                                    </div>
                                    <div class="form-group required">
                                        <input
                                            id="lastName"
                                            type="text"
                                            class="form-control"
                                            formControlName="lastName"
                                            required
                                            minlength="2"
                                            placeholder="Last Name"
                                            data-pw="lastNameInput"
                                        />
                                        <span class="text-danger" *ngIf="showRecipientLastNameRequiredError"
                                            >This field is required</span
                                        >
                                        <span class="text-danger" *ngIf="showRecipientLastNameMinLengthError"
                                            >Family name must be at least 2 characters long</span
                                        >
                                    </div>
                                    <div class="form-group required">
                                        <input
                                            id="email"
                                            type="email"
                                            class="form-control"
                                            formControlName="email"
                                            required
                                            placeholder="Email Address"
                                            data-pw="emailInput"
                                        />
                                        <span class="text-danger" *ngIf="showRecipientEmailRequiredError"
                                            >This field is required</span
                                        >
                                        <span class="text-danger" *ngIf="showRecipientEmailFormatError"
                                            >Must be a properly formatted email address</span
                                        >
                                    </div>
                                </form>
                            </ng-container>
                        </mat-radio-group>

                        <div class="form-group mt-3">
                            <label class="text-bold" for="optionalMessage">Add an optional message</label>
                            <p class="text-normal">
                                If you want to include a personal message in the email we send to the contact, add it
                                below.
                            </p>
                            <textarea
                                rows="4"
                                id="optionalMessage"
                                class="form-control"
                                [formControl]="optionalMessageFormControl"
                                placeholder="Add an optional message"
                                data-pw="optionalMessageInput"
                            ></textarea>
                        </div>
                        <div class="concierge-container" *ngIf="hasConciergeAssessmentsEnabled">
                            <div class="row mb-2">
                                <div class="col">
                                    <h4 class="mb-1">
                                        <strong> Add on Assessment Concierge Service </strong>
                                    </h4>
                                </div>
                            </div>
                            <div class="row mb-2" *ngIf="!doesCurrentUserHaveAvailableConciergeAssessments">
                                <div class="col-sm-1">
                                    <mat-icon fontSet="material-symbols-outlined">lock</mat-icon>
                                </div>
                                <div class="col-sm-11">
                                    You do not have any more available concierge assessments. Please contact
                                    <a href="mailto:sales@visotrust.com">sales&#64;visotrust.com</a> for more
                                    information.
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-sm-1">
                                    <mat-checkbox
                                        *ngIf="doesCurrentUserHaveAvailableConciergeAssessments"
                                        id="conciergeAssessmentCheckbox"
                                        [formControl]="conciergeAssessmentFormControl"
                                    ></mat-checkbox>
                                </div>
                                <div class="col-sm-11">
                                    Our audit team will reach out to your vendor to collect documents. Then, we will
                                    schedule a call with the vendor for a detailed follow-up conversation about their
                                    cybersecurity strategy, roadmap, approach, and preparedness.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <mat-radio-button [value]="AssessmentInfoTypeValue.UPLOAD_ARTIFACTS" data-pw="uploadArtifactsRadio">
                    Upload artifacts on behalf of {{ thirdPartyOrgName }}
                </mat-radio-button>
                <ng-container *ngIf="assessmentInfoTypeValue === AssessmentInfoTypeValue.UPLOAD_ARTIFACTS">
                    <div class="mt-2 selected-option-content">
                        <p class="d-block mb-2 text-normal">
                            Third party audit artifacts provide a higher level of assurance and are preferred.
                            Certificates alone are insufficient.
                        </p>
                        <div class="mb-2 d-flex align-items-center" [appPopover]="uploadExamples" type="button">
                            <a href="javascript:void(0)" class="me-1 mat-text-secondary"
                                >View additional guidance and examples</a
                            >
                            <mat-icon class="mat-text-secondary" [inline]="true">info</mat-icon>
                        </div>

                        <ng-template #uploadExamples>
                            <div class="upload-examples">
                                <div class="p-3">
                                    <p class="text-bold mb-1">Provide recent third party audit documents such as:</p>
                                    <ul class="ps-3 mb-0">
                                        <li>
                                            CSA STAR Level 2 Certification/Attestation Report
                                            <p class="text-muted m-0">
                                                Include associated CAIQ and below mentioned ISO/SOC documents
                                            </p>
                                        </li>
                                        <li>
                                            ISO 27001 Cert and Statement of Applicability
                                            <p class="text-muted m-0">
                                                Provide a surveillance audit if certificates have been issued over 365
                                                days ago
                                            </p>
                                        </li>
                                        <li>
                                            SOC 2 Type 1/2 Report
                                            <p class="text-muted m-0">
                                                Include a bridge letter if this was issued over 365 days ago
                                            </p>
                                        </li>
                                        <li>PCI DSS Cert and Attestation of Compliance (On-site)</li>
                                        <li>HITRUST Certification/Validation Report</li>
                                    </ul>
                                </div>
                                <mat-divider class="my-0" />
                                <div class="p-3">
                                    <p class="text-bold">Provide recent third party penetration tests:</p>
                                    <ul class="ps-3 mb-0">
                                        <li>External, Internal and Application Penetration Test Reports</li>
                                        <li>Application Security Assessment Reports</li>
                                        <li>Executive summaries or redacted reports are acceptable</li>
                                    </ul>
                                </div>
                                <mat-divider class="my-0" />
                                <div class="p-3">
                                    <p class="text-bold">Provide alternative attestations:</p>
                                    <ul class="ps-3 mb-0">
                                        <li>Published security policies/standards and table of contents</li>
                                        <li>CSA STAR, Shared Assessments, PCI DSS, HITRUST or VSA self assessment</li>
                                        <li>Security Overview Document</li>
                                    </ul>
                                </div>
                            </div>
                        </ng-template>
                        <div class="mb-2">
                            <button
                                mat-button
                                visoButton="text"
                                class="full-width p-3 mb-2 drag-drop-message-container"
                                (click)="fileInput.click()"
                            >
                                <span class="text-dark">
                                    Drag and Drop or <span class="text-bold text-secondary">Browse</span>
                                </span>
                            </button>
                            <input
                                #fileInput
                                type="file"
                                ng2FileSelect
                                multiple
                                class="d-none"
                                [uploader]="uploader"
                                data-pw="uploadArtifactsFileInput"
                            />
                        </div>

                        <div class="artifact-files-container">
                            <ng-container *ngFor="let item of uploader.queue">
                                <app-document-file
                                    class="mb-2"
                                    [disabled]="uploadingFiles$ | async"
                                    [documentFileName]="item?.file?.name"
                                    [includePassword]="true"
                                    (onPasswordSaved)="setArtifactPassword($event, item)"
                                    (onFileRemoved)="removeItem(item)"
                                ></app-document-file>
                            </ng-container>

                            <small class="text-muted text-center d-block" *ngIf="uploader.queue.length === 0">
                                No artifacts uploaded yet
                            </small>
                        </div>
                    </div>
                </ng-container>

                <mat-radio-button [value]="AssessmentInfoTypeValue.PUBLIC_URLS" data-pw="publicUrlsAssessmentRadio">
                    Share one or more URLs that were provided to you by {{ thirdPartyOrgName }}
                </mat-radio-button>
                <ng-container *ngIf="assessmentInfoTypeValue === AssessmentInfoTypeValue.PUBLIC_URLS">
                    <div class="form-group mt-3 selected-option-content">
                        <div class="d-flex">
                            <textarea
                                [formControl]="publicDocUrlFormControl"
                                class="form-control me-3 mw-80"
                                placeholder="Enter one or more URLs"
                                data-pw="publicDocsUrlsInput"
                            >
                            </textarea>
                            <button
                                mat-stroked-button
                                color="secondary"
                                class="btn-md align-self-center add-url-button"
                                type="button"
                                [disabled]="addUrlButtonDisabled"
                                (click)="addUrl()"
                                data-pw="addUrlPublicDocsAssessmentButton"
                            >
                                Add URL
                            </button>
                        </div>
                        <span class="text-danger full-flex-row" *ngIf="showPublicDocUrlError"
                            >Please provide a valid url</span
                        >

                        <ng-container *ngIf="publicDocsUrlsValues$ | async as publicDocsUrlsValues">
                            <div class="d-flex flew-wrap mt-2">
                                <app-badge
                                    *ngFor="let url of publicDocsUrlsValues; let i = index"
                                    [type]="'light-primary'"
                                    extraCssClasses="lead me-2"
                                >
                                    {{ url }}
                                    <em class="fa fa-times ms-1 cursor-pointer" (click)="removeUrl(i)"></em>
                                </app-badge>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <mat-radio-button
                    [value]="AssessmentInfoTypeValue.CONTINUE_WITH_PUBLIC_INFO"
                    data-pw="otherArtifactAttestationOption"
                >
                    Assess {{ thirdPartyOrgName }} with publicly available information
                </mat-radio-button>

                <div class="mt-3" *jhiHasAnyAuthority="VisoUserRole.Auditor">
                    <label class="text-bold">Select an Assessment Creator from Client Org</label>
                    <mat-select
                        required
                        class="pl-4 pr-4 ml-md-4"
                        [formControl]="assessmentCreatorFormControl"
                        data-pw="assessmentCreatorSelect"
                        placeholder="Select an assessment creator"
                    >
                        @for (potentialCreator of potentialAssessmentCreators$ | async; track potentialCreator.ref.id) {
                            <mat-option [value]="potentialCreator.ref">
                                {{ potentialCreator.name }}
                            </mat-option>
                        }
                    </mat-select>
                </div>
            </mat-radio-group>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <small
            *ngIf="showSelectAtLeastOneMethodError && startAssessmentFormGroup.dirty"
            class="text-danger mb-1 full-flex-row text-end"
        >
            Please select one method in order to start an assessment.
        </small>
        <button mat-button visoButton="secondary" class="me-2" (click)="close()">Cancel</button>
        <button
            mat-button
            visoButton="primary"
            toggleDisableOnActions
            [disabled]="startAssessmentFormGroup.invalid"
            [disablingActions]="[AssessmentActions.StartAssessmentRequest]"
            [enablingActions]="[
                AssessmentActions.StartAssessmentRequestSuccess,
                AssessmentActions.StartAssessmentRequestFailed,
            ]"
            (click)="submit()"
        >
            {{ submitButtonLabel }}
        </button>
    </mat-dialog-actions>
</div>
