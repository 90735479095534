import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PrimaryVendorContact } from './primary-vendor-contact.model';

export type EntityResponseType = HttpResponse<PrimaryVendorContact>;

@Injectable({
    providedIn: 'root',
})
export class PrimaryVendorContactService {
    private _relationshipsResourceUrl = 'api/relationships';

    constructor(private _http: HttpClient) {}

    save(primaryVendorContact: PrimaryVendorContact): Observable<void> {
        return this._http.post<void>(
            `${this._relationshipsResourceUrl}/${primaryVendorContact.relationshipId}/primary-contact`,
            primaryVendorContact,
        );
    }

    remove(relationshipId: number, primaryContactId: number): Observable<HttpResponse<any>> {
        return this._http.delete<any>(
            `${this._relationshipsResourceUrl}/${relationshipId}/primary-contact/${primaryContactId}`,
            {
                observe: 'response',
            },
        );
    }
}
