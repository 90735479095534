export class UrlUtils {
    /**
     * Returns whether the URL contains the specified 2LD.
     * @example
     * // returns true
     * urlContainsSecondLevelDomain("https://test.com", "test");
     * // returns true
     * urlContainsSecondLevelDomain("test.io", "test");
     * // returns false
     * urlContainsSecondLevelDomain("google.com", "test");
     */
    static urlContainsSecondLevelDomain(url: string, domain: string): boolean {
        return new RegExp(`${domain}\\.[a-zA-Z]{2,}`).test(url);
    }
}
