import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'ngx-slider-v2';
import { of, BehaviorSubject, Observable, filter, switchMap } from 'rxjs';
import { catchError, finalize, map, tap, take } from 'rxjs/operators';
import { DataTypeCategory } from '../../../entities/data-type';
import { FormUtilsService } from '../../../shared/utils/form-utils.service';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import { DataTypeConfig } from '../client-profile.model';
import { ClientProfileService } from '../client-profile.service';
import { OrgControlDomainConfig } from '../client-profile.model';
import { ControlDomain, ControlDomainType } from '../../../entities/control-domain';
import { select, Store } from '@ngrx/store';
import { getControls } from '../../../routes/controls/redux/controls.selectors';
import { DataSensitivityLevel } from '../data-sensitivity-levels-config/data-sensitivity-level.model';

@Component({
    selector: 'app-data-types-config',
    templateUrl: './data-types-config.component.html',
    styleUrls: ['./data-types-config.component.scss'],
})
export class DataTypesConfigComponent implements OnInit {
    @Input()
    set dataTypes(dataTypes: DataTypeConfig[]) {
        this.systemDataTypes = dataTypes
            .filter((d) => !d.dataType?.custom)
            .sort((a, b) => a.dataType.name.localeCompare(b.dataType.name));
        this.customDataTypes = dataTypes
            .filter((d) => d.dataType?.custom)
            .sort((a, b) => a.dataType.name.localeCompare(b.dataType.name));
    }

    @Input() set profileControls(value: OrgControlDomainConfig[]) {
        this._profileControls$.next(value);
    }

    @Input() clientProfileId: string;
    @Input() orgId: number;
    @Output() dataTypeSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

    dataTypeCategories = Object.values(DataTypeCategory);
    orgProfileControls$: Observable<ControlDomain[]>;

    manageDataTypesConfigModal: NgbModalRef;
    systemDataTypes: DataTypeConfig[];
    customDataTypes: DataTypeConfig[];
    customDataTypesMessages = {
        emptyMessage: 'There are no custom Data Types.',
    };

    sliderOptions: Options = {
        floor: 0,
        ceil: 1,
        step: 0.1,
        readOnly: true,
        disabled: true,
        showSelectionBar: true,
        selectionBarGradient: {
            from: '#FFF',
            to: '#F00',
        },
        hideLimitLabels: true,
        hidePointerLabels: false,
    };

    savingDataType: boolean = false;
    currentDataTypeOrg: DataTypeConfig;
    customDataTypesForm: UntypedFormGroup;

    private _profileControls$ = new BehaviorSubject<OrgControlDomainConfig[]>([]);

    constructor(
        private _modalService: NgbModal,
        private _clientProfileService: ClientProfileService,
        private _fb: UntypedFormBuilder,
        private _formUtils: FormUtilsService,
        private _store$: Store,
    ) {}

    get showNameRequiredError(): boolean {
        const nameFormControl = this.customDataTypesForm.controls.name;
        return nameFormControl.hasError('required') && this.isNameDirtyOrTouched;
    }

    get showCategoryRequiredError(): boolean {
        const categoryFormControl = this.customDataTypesForm.controls.category;
        return categoryFormControl.hasError('required') && this.isNameDirtyOrTouched;
    }

    get showDescriptionRequiredError(): boolean {
        const descriptionFormControl = this.customDataTypesForm.controls.description;
        return descriptionFormControl.hasError('required') && this.isDescriptionDirtyOrTouched;
    }

    get showSensitivityRequiredError(): boolean {
        const sensitivityFormControl = this.customDataTypesForm.controls.sensitivity;
        return sensitivityFormControl.hasError('required') && this.isSensitivityDirtyOrTouched;
    }

    get showSensitivityValueLimitError(): boolean {
        const sensitivityFormControl = this.customDataTypesForm.controls.sensitivity;
        return (
            (sensitivityFormControl.hasError('min') || sensitivityFormControl.hasError('max')) &&
            this.isSensitivityDirtyOrTouched
        );
    }

    private get isNameDirtyOrTouched(): boolean {
        const nameFormControl = this.customDataTypesForm.controls.name;
        return nameFormControl.dirty || nameFormControl.touched;
    }

    private get isDescriptionDirtyOrTouched(): boolean {
        const descriptionFormControl = this.customDataTypesForm.controls.description;
        return descriptionFormControl.dirty || descriptionFormControl.touched;
    }

    private get isSensitivityDirtyOrTouched(): boolean {
        const sensitivityFormControl = this.customDataTypesForm.controls.sensitivity;
        return sensitivityFormControl.dirty || sensitivityFormControl.touched;
    }

    ngOnInit(): void {
        this.orgProfileControls$ = this._profileControls$.pipe(
            filter(Boolean),
            map((profileControls) => Object.values(profileControls)),
            switchMap((profileControls) =>
                this._store$.pipe(
                    select(getControls),
                    map((controls) => ({ profileControls, controls })),
                    take(1),
                ),
            ),
            map(({ profileControls, controls }) => {
                controls.map((c) => {
                    c.disabled = !profileControls?.find((pc) => pc.controlDomainId === c.id);
                    return c;
                });
                return controls
                    .filter((c) => c.controlDomainType !== ControlDomainType.SUPPLEMENTAL_QUESTIONNAIRE)
                    .sort((a, b) => a.name.localeCompare(b.name));
            }),
        );
    }

    getBadgeCssClass(status: boolean) {
        return status ? 'primary' : 'danger';
    }

    toggleDataTypeStatus(dataTypeConfig: DataTypeConfig): void {
        dataTypeConfig.enabled = !dataTypeConfig.enabled;

        if (dataTypeConfig.enabled) {
            this._clientProfileService
                .enableDataTypeForOrg(this.clientProfileId, dataTypeConfig.dataType?.id)
                .pipe(
                    catchError(() => of(null)),
                    tap(() => (this.savingDataType = false)),
                )
                .subscribe();
        } else {
            this._clientProfileService
                .disableDataTypeForOrg(this.clientProfileId, dataTypeConfig.dataType?.id)
                .pipe(
                    catchError(() => of(null)),
                    tap(() => (this.savingDataType = false)),
                )
                .subscribe();
        }
    }

    openManageCustomDataTypeModal(manageCustomConfigModal: any, dataTypeConfig: DataTypeConfig = null): void {
        this.currentDataTypeOrg = dataTypeConfig;
        this.buildCustomDataTypesForm(dataTypeConfig);
        this.manageDataTypesConfigModal = this._modalService.open(manageCustomConfigModal, {
            centered: true,
            backdrop: 'static',
        });
    }

    closeManageCustomDataTypeModal() {
        this.currentDataTypeOrg = null;
        this.manageDataTypesConfigModal?.close();
    }

    submitDataType() {
        if (!this.customDataTypesForm.invalid) {
            this.savingDataType = true;
            const payload = this._formUtils.getCleanFormGroupValue(this.customDataTypesForm);
            let dataTypeConfig: DataTypeConfig = null;

            if (!this.currentDataTypeOrg?.dataType.id || !this.currentDataTypeOrg?.dataType.custom) {
                dataTypeConfig = {
                    dataType: {
                        name: payload.name,
                        description: payload.description,
                        sensitivity: payload.sensitivity,
                        orgId: this.orgId,
                        exampleText: payload.exampleText,
                        category: payload.category,
                        relevantControlDomainIds: payload.relevantControlDomainIds,
                    },
                    enabled: false,
                };

                this._clientProfileService
                    .createCustomDatatypeForOrg(this.clientProfileId, dataTypeConfig)
                    .pipe(
                        tap(() => this.closeManageCustomDataTypeModal()),
                        finalize(() => (this.savingDataType = false)),
                    )
                    .subscribe({
                        next: () => this.dataTypeSubmitted.emit(true),
                        error: () => of(null),
                    });
            } else {
                this.currentDataTypeOrg.dataType.name = payload.name;
                this.currentDataTypeOrg.dataType.description = payload.description;
                this.currentDataTypeOrg.dataType.sensitivity = payload.sensitivity;
                this.currentDataTypeOrg.dataType.category = payload.category;
                this.currentDataTypeOrg.dataType.exampleText = payload.exampleText;
                this.currentDataTypeOrg.dataType.relevantControlDomainIds = payload.relevantControlDomainIds;

                this._clientProfileService
                    .updateCustomDataTypeForOrg(this.clientProfileId, this.currentDataTypeOrg)
                    .pipe(
                        tap(() => this.closeManageCustomDataTypeModal()),
                        finalize(() => (this.savingDataType = false)),
                    )
                    .subscribe({
                        next: () => this.dataTypeSubmitted.emit(true),
                        error: () => of(null),
                    });
            }
        }
    }

    public getSensitivityValueForBar(sensitivity: number): number {
        return +(sensitivity * 100).toFixed(2);
    }

    public getSensitivityLevelFromValue(value: number): DataSensitivityLevel {
        if (value === 0) {
            return DataSensitivityLevel.NONE;
        } else if (value < 0.4) {
            return DataSensitivityLevel.LOW;
        } else if (value < 0.71) {
            return DataSensitivityLevel.MEDIUM;
        } else if (value < 0.9) {
            return DataSensitivityLevel.HIGH;
        } else if (value <= 1) {
            return DataSensitivityLevel.EXTREME;
        } else {
            return DataSensitivityLevel.NONE;
        }
    }

    private buildCustomDataTypesForm(dataTypeConfig?: DataTypeConfig) {
        this.customDataTypesForm = this._fb.group({
            name: [dataTypeConfig?.dataType?.name || '', [Validators.required, noWhitespaceValidator]],
            description: [dataTypeConfig?.dataType?.description || '', [Validators.required, noWhitespaceValidator]],
            sensitivity: [
                dataTypeConfig?.dataType?.sensitivity || 0,
                [Validators.required, Validators.min(0), Validators.max(1), noWhitespaceValidator],
            ],
            category: [dataTypeConfig?.dataType?.category || '', [Validators.required]],
            exampleText: [dataTypeConfig?.dataType?.exampleText || ''],
            relevantControlDomainIds: [dataTypeConfig?.dataType?.relevantControlDomainIds || []],
        });
    }
}
